<template>
	<h2 v-tw-class="'text-lg font-bold text-hub-text'">
		<slot></slot>
	</h2>
</template>

<style scoped>
	h2 {
		font-family: 'work_sansregular';
	}
</style>
