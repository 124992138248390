<template>
	<h1 v-tw-class="'text-xl md:text-2xl text-hub-text'">
		<slot></slot>
	</h1>
</template>

<style scoped>
	h1 {
		font-family: 'work_sanssemibold';
	}
</style>
