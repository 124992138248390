import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-0 h-full z-20 bg-gray-middle opacity-75"
}
const _hoisted_2 = {
  key: 1,
  class: "text-left py-1 h-full overflow-y-auto scrollbar pr-4"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex flex-row-reverse gap-2 justify-between" }

import { onMounted, useSlots, computed, onUnmounted } from 'vue';
	import { DialogButton, DialogButtonAction, DialogOk, DialogCancel, useDialog } from '@/store/dialog';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog',
  props: {
		title: {
			type: String,
			default: '',
		},
		width: {
			type: String,
			default: 'w-4/5 md:w-3/5 lg:w-2/5',
		},
		buttons: {
			type: Array<DialogButton>,
			default: [],
		},
	},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	const emit = __emit;
	const dialog = useDialog();
	const slots = useSlots();

	const hasContent = computed(() => {
		return slots['default'] || dialog.properties.content !== '';
	});

	const props = __props;

	onUnmounted(() => {
		dialog.hideModal();
	});

	onMounted(() => {
		if (props.title !== '') {
			dialog.properties.title = props.title;
		}
		if (props.buttons.length > 0) {
			dialog.properties.buttons = props.buttons;
		}

		dialog.showModal();
	});

	function doAction(action: DialogButtonAction) {
		emit('close', action);
		dialog.hideModal();
		dialog.close(action);
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_H2 = _resolveComponent("H2")!
  const _component_Line = _resolveComponent("Line")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    onKeydown: [
      _cache[3] || (_cache[3] = _withKeys(($event: any) => (doAction(_unref(DialogCancel))), ["esc"])),
      _cache[4] || (_cache[4] = _withKeys(($event: any) => {
			if (_unref(dialog).properties.buttons.some((b) => b.action == 1 && b.enabled)) {
				doAction(_unref(DialogOk));
			}
		}, ["enter"]))
    ],
    class: "absolute h-full w-full top-0 left-0"
  }, [
    (_unref(dialog).properties.modal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (!_unref(dialog).properties.modalonly)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "absolute inset-0 h-full flex z-20 py-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (doAction(_unref(DialogCancel))))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["theme-light m-auto max-h-full p-4 rounded-lg shadow-xl shadow-black bg-white flex flex-col justify-between gap-1", __props.width]),
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", null, [
              (_unref(dialog).properties.close)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    type: "close",
                    size: "md",
                    class: "float-right -mt-1 hover:opacity-75",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (doAction(_unref(DialogCancel))))
                  }))
                : _createCommentVNode("", true),
              (_unref(dialog).properties.title !== '')
                ? (_openBlock(), _createBlock(_component_H2, {
                    key: 1,
                    class: "m-0 text-left text-black"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(dialog).properties.title), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "header")
            ]),
            (hasContent.value)
              ? (_openBlock(), _createBlock(_component_Line, {
                  key: 0,
                  class: "z-0"
                }))
              : _createCommentVNode("", true),
            (hasContent.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "default"),
                  (_unref(dialog).properties.content !== '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(dialog).properties.content), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Line, { class: "z-0" }),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dialog).properties.buttons, (button, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createVNode(_component_Button, {
                    color: button.color,
                    onClick: ($event: any) => (doAction(button.action)),
                    disabled: !button.enabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('dialog.' + button.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["color", "onClick", "disabled"])
                ]))
              }), 128))
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})