import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full overflow-hidden" }
const _hoisted_2 = { class: "flex-1 overflow-y-auto scrollbar" }
const _hoisted_3 = { class: "z-10" }

import { headerSizes } from '@/assets/sizes';
	import { computed } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderFooter',
  props: {
		headerSize: {
			type: String,
			default: 'base',
		},
		headerBgColor: {
			type: String,
			default: 'bg-hub-background-2',
		},
		headerMobilePadding: {
			type: Boolean,
			default: false,
		},
	},
  setup(__props) {

	const props = __props;

	const headerClass = computed(() => {
		let c = headerSizes[props.headerSize] + ' ' + props.headerBgColor;

		if (props.headerMobilePadding) {
			c += ' md:px-6 md:pt-4 pl-20 ';
		} else {
			c += ' px-6 pt-4 ';
		}
		return c;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([headerClass.value, "flex flex-col flex-1 justify-center z-10 relative"])
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ]))
}
}

})