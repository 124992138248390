import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  class: "text-xs text-nowrap font-normal",
  style: {"margin-top":"3px"}
}

import { useUserColor } from '@/composables/useUserColor';
	import filters from '@/core/filters';
	import { computed } from 'vue';

	import Room from '@/model/rooms/Room';
	import { useSettings } from '@/store/settings';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'UserDisplayName',
  props: {
		user: {
			type: String,
			required: true,
		},
		room: {
			type: Room,
			required: true,
		},
	},
  setup(__props) {

	const { color, textColor } = useUserColor();

	const settings = useSettings();

	const props = __props;

	const displayName = computed(() => {
		const member = props.room.getMember(props.user);
		// If the display name is not set, we get user Id. In that case return empty display name.
		if (props.user === member?.rawDisplayName || filters.extractPseudonym(props.user) === member?.rawDisplayName) return '';
		// Conditionally renders displayName
		return member?.rawDisplayName;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: "flex flex-row gap-x-2 items-start",
    title: displayName.value
  }, [
    (displayName.value)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          "data-testid": "display-name",
          class: _normalizeClass(`${_unref(textColor)(_unref(color)(__props.user))} text-nowrap font-semibold text-sm`)
        }, _toDisplayString(_unref(filters).maxLengthText(displayName.value, _unref(settings).getDisplayNameMaxLength)), 3))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(filters).extractPseudonym(__props.user)), 1)
  ], 8, _hoisted_1))
}
}

})